<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "SignUp",
  metaInfo: {
    title: "Sign up",
    titleTemplate: "%s | Tentifly - Smart sales | booking assistant"
  },
  extends: View,
  mixins: [
    LoadSections([
      //"hero-alt",
      "sign-up"
      //'map',
      //'newsletter',
    ])
  ],
  props: {
    id: {
      type: String,
      default: "about"
    }
  }
};
</script>
